import styles from './Footer.module.sass';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useTranslation } from 'react-i18next';
import { FaDiscord } from 'react-icons/fa';
function Footer() {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      {/* <img src={footerBanner} alt='footer' className={styles.footerBanner} /> */}
      <div className={styles.footer}>
        <div className={styles.footerIconBox}>
          <FacebookIcon className={styles.footerIcon} />
          <FaDiscord className={styles.footerIcon} />
          <TwitterIcon className={styles.footerIcon} />
        </div>
        <div className={styles.footer_text}> {t('footer')}</div>
      </div>
    </div>
  );
}

export default Footer;
