import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import {
  Alert,
  Collapse,
  Container,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
} from '@mui/material';
import classes from './Layout.module.sass';
import DrawerContent from './DrawerContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import Footer from '../components/Footer/Footer';
import logo from '../assets/Images/logohorizontal.png';
import logoNew from '../assets/Images/logoCX.png';
import { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import NavBar from './NavBar';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';

interface Props {
  window?: () => Window;
}
const Layout = ({ children }: { children: React.ReactNode }, props: Props) => {
  // eslint-disable-next-line react/prop-types
  const { window } = props;

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [showWarningBanner, setShowWarningBanner] = React.useState(false);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const drawerWidth = !isMobile ? 280 : 280;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const navigate = useNavigate();

  const drawer = <DrawerContent />;

  const container = window !== undefined ? () => window().document.body : undefined;


  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <AppBar
        position='fixed'
        elevation={0}
        sx={{
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          display: 'flex',
          backdropFilter: 'blur(24px)',
          // paddingRight: '20px',
          alignItems: 'center',
          background: 'transparent',
          padding: '5px 0px',

          // background: {
          //   xs: 'linear-gradient(rgba(0, 2, 5, 0.25), rgba(0, 2, 5, 0.5))',
          //   lg: 'transparent',
          //   md: 'linear-gradient(rgba(159, 164, 167, 0.25), rgba(0, 2, 5, 0.5))',
          //   sm: 'linear-gradient(rgba(0, 2, 5, 0.25), rgba(0, 2, 5, 0.5))',
          // },
        }}
      >
        <Box
          sx={{
            minWidth: { lg: '100%', xs: '100%' },
          }}
        >
          <Collapse in={showWarningBanner}>
            <Alert
              variant='filled'
              sx={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                borderRadius: '0',
                color: 'black',
                margin: 0,
              }}
              severity='warning'
            >
              Your wallet is not connected to Binance. Please change your network.{' '}
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setShowWarningBanner(false);
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            </Alert>
          </Collapse>
        </Box>
        <Container>
          <Toolbar disableGutters className={classes.navbarflex}>
            <Box className={classes.appBar}>
              <IconButton
                color='primary'
                aria-label='open drawer'
                edge='start'
                onClick={handleDrawerToggle}
                sx={{  display: { lg: 'none' }, ml: '0px' }}
              >
                <MenuIcon className={classes.menuicon} />
              </IconButton>
              <img
                src={logoNew}
                className={classes.logoimage}
                // width='120px'
                onClick={() => {
                  navigate('/Home');
                }}
              />
            </Box>
            <NavBar />
          </Toolbar>
        </Container>
      </AppBar>

      <Box
        component='nav'
        sx={{
          width: { lg: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label='mailbox folders'
        onClick={() => handleDrawerToggle()}
        // onKeyDown={() => handleDrawerToggle()}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: 'transparent',
              color: 'white',
              backdropFilter: 'blur(40px)',
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,

              backgroundColor: 'transparent',
              color: 'white',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
   

      <Box
        component='main'
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' },
          color: 'white',
          // px: { xs: 0, lg: 4 },
        }}
      >
        <Toolbar />

        <Box sx={{ my: 2 }}>
          {children}
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
