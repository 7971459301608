import axios, { AxiosError } from 'axios';

const httpClient = axios.create({
  baseURL: 'https://api.cockboxing.live/v1',
  // baseURL: 'https://api.cockboxing.live:3200/v1',
  // baseURL: 'http://localhost:443/v1',
  // baseURL: 'http://localhost:3200/v1',

});

const firebaseClient = axios.create({
  baseURL: 'https://api.cockboxing.live/v1',
  // baseURL: 'https://api.cockboxing.live:3200/v1',
  // baseURL: 'http://localhost:443/v1',
  // baseURL: 'http://localhost:3200/v1',

});

export async function CreateUser(data: any, accessToken: any) {
  let response;
  try {
    response = await httpClient({
      method: 'post',
      headers: {
        'x-access-token': accessToken,
      },
      url: '/user/signup',
      data: data,
    });
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 404) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e: any) {
    console.log('get create user exception', e);
    throw new Error(e.response.data.error);
  }
}

export async function GetClaimRoot(group: string) {
  let response;
  try {
    response = await httpClient.get(`/claim/getRoot?group=${group}`);
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('get root exception', e);
  }
}

export async function createCryptoPaymentAPI(accessToken: string, body: unknown) {
  let response;
  try {
    response = await httpClient({
      method: 'post',
      url: '/deposit/create',
      headers: {
        'x-access-token': accessToken,
      },
      data: body,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    if (e instanceof AxiosError) {
      console.log('create crypto payment exception', e.response?.data.error);
      throw new Error(e.response?.data.error);
    }
  }
}

export async function PostCreateProject(data: any) {
  let response;
  try {
    response = await httpClient({
      method: 'post',
      url: '/createProject',
      data: data,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('create project data exception', e);
  }
}

export async function GetLiveFight(accessToken: string, uid: string) {
  let response;
  try {
    response = await httpClient.get(`/fight/liveFight?uid=${uid}`, {
      headers: {
        'x-access-token': accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e: any) {
    console.log('get live fight exception', e);
    // return e.response.data;
  }
}
export async function GetMediaServices() {
  let response;
  try {
    response = await httpClient.get('/mediaservices', {
      headers: {},
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e: any) {
    console.log('get live fight exception', e);
    // return e.response.data;
  }
}
export async function GetAllFights(accessToken: string) {
  let response;
  try {
    response = await httpClient.get('/fight/allFights', {
      headers: {
        'x-access-token': accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('get all fights exception', e);
  }
}

export async function GetUpcomingFights(
  accessToken: string,
  page: number,
  uid: string,
  matchDate: string,
) {
  let response;
  try {
    response = await firebaseClient.get(
      `/fight/upcomingFights?page=${page}&uid=${uid}&matchDate=${matchDate}`,
      {
        headers: {
          'x-access-token': accessToken,
        },
      },
    );
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('get upcoming fight exception', e);
  }
}
export async function GetTransactionbyId(accessToken: string, uid: string) {
  let response;
  try {
    response = await httpClient.get(`/transaction/${uid}`, {
      headers: {
        'x-access-token': accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('get transaction by id exception', e);
  }
}
export async function GetMoreTransactionbyId(accessToken: string, uid: string, page: number) {
  let response;
  try {
    response = await httpClient.get(`/transaction/${uid}?page=${page}`, {
      headers: {
        'x-access-token': accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('get more transaction exception', e);
  }
}
export async function GetBalance(accessToken: string, uid: string) {
  let response;
  try {
    response = await firebaseClient.get(`/wallet/balance/${uid}`, {
      headers: {
        'x-access-token': accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('get balance exception', e);
  }
}
export async function StakeHistory(accessToken: string, uid: string) {
  let response;
  try {
    response = await httpClient.get(`/stake/history/${uid}?limit=20`, {
      headers: {
        'x-access-token': accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('get stake history exception', e);
  }
}
export async function GetMoreHistory(accessToken: string, uid: string, page: number) {
  let response;
  try {
    response = await httpClient.get(`/stake/history/${uid}?page=${page}&limit=20`, {
      headers: {
        'x-access-token': accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('get more history exception', e);
  }
}

export async function PostStake(body: any, accessToken: string) {
  let response;
  try {
    response = await httpClient({
      method: 'post',
      url: '/stake/create',
      data: body,
      headers: {
        'x-access-token': accessToken,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e: any) {
    console.log('post fight exception', e);
    return e.response.data;
  }
}
export async function GetMatchHistory(accessToken: string, matchDate: string) {
  let response;
  try {
    response = await firebaseClient.get(`/fight/endFights?limit=20&matchDate=${matchDate}`, {
      headers: {
        'x-access-token': accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('get all fights exception', e);
  }
}

export async function GetMoreMatchHistory(accessToken: string, page: number, matchDate: string) {
  let response;
  try {
    response = await httpClient.get(
      `/fight/endFights?page=${page}&limit=20&matchDate=${matchDate}`,
      {
        headers: {
          'x-access-token': accessToken,
        },
      },
    );
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('get all more fights exception', e);
  }
}
export async function GetAccount(accessToken: string, uid: string) {
  let response;
  try {
    response = await httpClient.get(`/user/details/${uid}`, {
      headers: {
        'x-access-token': accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('get account exception', e);
  }
}

export async function StakeByFight(accessToken: string, number: number, body: any, date: string) {
  let response;
  try {
    response = await httpClient({
      method: 'post',
      url: `/stake/createByFightNo/${number}?matchDate=${date}`,
      data: body,
      headers: {
        'x-access-token': accessToken,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e: any) {
    return e.response.data;
  }
}

export async function SendBalance(accessToken: string, body: any) {
  let response;
  try {
    response = await httpClient({
      method: 'post',
      url: '/transaction/sendBalance',
      data: body,
      headers: {
        'x-access-token': accessToken,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e: any) {
    return e.response.data;
  }
}

export async function GetAuthenticatorQr(accessToken: string, uid: string) {
  let response;
  try {
    response = await httpClient.get(`/2FA/authenticatorQr?uid=${uid}`, {
      headers: {
        'x-access-token': accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('get authenticator exception', e);
  }
}

export async function Getis2FAEnabled(email: string) {
  let response;
  try {
    response = await httpClient.get(`/2FA/is2FAEnabled?email=${email}`, {});
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('get is2FA exception', e);
  }
}

export async function PostEnable2FA(accessToken: string, uid: string, token: number) {
  let response;
  try {
    response = await httpClient({
      method: 'post',
      url: `/2FA/enable2FA?uid=${uid}&token=${token}`,
      headers: {
        'x-access-token': accessToken,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e: any) {
    return e.response.data;
  }
}

export async function PostDisable2FA(accessToken: string, uid: string, token: number) {
  let response;
  try {
    response = await httpClient({
      method: 'post',
      url: `/2FA/disable2FA?uid=${uid}&token=${token}`,
      headers: {
        'x-access-token': accessToken,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e: any) {
    return e.response.data;
  }
}

export async function PostVerify2FA(email: string, token: number) {
  let response;
  try {
    response = await httpClient({
      method: 'post',
      url: `/2FA/verify2FA?email=${email}&token=${token}`,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e: any) {
    return e.response.data;
  }
}

export async function GetisValidRegion() {
  let response;
  try {
    response = await axios.get('https://stake.sntmainnet.com/isValid');
    console.log('response', response);
    if (response.data.data === 1) {
      return true;
    } else {
      return false;
    }
  } catch (e: any) {
    console.log('get isValidRegion', e);
    return false;
  }
}

export async function GetCockPickDetails(accessToken: string, date: string) {
  let response;
  try {
    response = await httpClient.get(`/cockpick/details/${date}`, {
      headers: {
        'x-access-token': accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('get cock pick details exception', e);
  }
}

export async function PostCockPickStake(
  accessToken: string,
  matchNo: number,
  date: string,
  body: any,
) {
  let response;
  try {
    response = await httpClient({
      method: 'post',
      url: `/cockpick/create/${matchNo}?matchDate=${date}`,
      headers: {
        'x-access-token': accessToken,
      },
      data: body,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e: any) {
    return e.response.data;
  }
}

export async function GetCockPickHistory(accessToken: string, uid: string) {
  let response;
  try {
    response = await httpClient.get(`/cockpick/history/${uid}?limit=20`, {
      headers: {
        'x-access-token': accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('get cockpick history exception', e);
  }
}
export async function GetCockPickMoreHistory(accessToken: string, uid: string, page: number) {
  let response;
  try {
    response = await httpClient.get(`/cockpick/history/${uid}?page=${page}&limit=20`, {
      headers: {
        'x-access-token': accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('get cockpick more history exception', e);
  }
}

export async function GetCockPickWinnerHistory(accessToken: string) {
  let response;
  try {
    response = await httpClient.get('/cockpick/rewardhistory?limit=20', {
      headers: {
        'x-access-token': accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('get cockpick history exception', e);
  }
}
export async function GetCockPickWinnerHistoryMore(accessToken: string, page: number) {
  let response;
  try {
    response = await httpClient.get(`/cockpick/rewardhistory?page=${page}&limit=20`, {
      headers: {
        'x-access-token': accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('get cockpick more history exception', e);
  }
}
export async function CreateWidraw(accessToken: string, body: unknown) {
  let response;
  try {
    response = await httpClient({
      method: 'post',
      url: '/withdraw/create',
      headers: {
        'x-access-token': accessToken,
      },
      data: body,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    if (e instanceof AxiosError) {
      console.log('create crypto payment exception', e.response?.data.message);
      throw new Error(e.response?.data.message);
    }
  }
}
export async function GetAllWithdraw(id:string,page:number,accessToken: string) {
  let response;
  try {
    response = await httpClient.get('/withdraw/user/'+id+'?page='+page, {
      headers: {
        'x-access-token': accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e: any) {
    console.log('get live fight exception', e);
    // return e.response.data;
  }
}
export async function GetAffiliate(id: string) {
  let response;
  try {
    response = await httpClient.get(`/affiliate/${id}`, {
      // headers: {
      //   'x-access-token': accessToken,
      // },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e: any) {
    console.log('get live fight exception', e);
    // return e.response.data;
  }
}
export async function UpdateUser(data: any, uid: string, accessToken: any) {
  let response;
  try {
    response = await httpClient({
      method: 'put',
      headers: {
        'x-access-token': accessToken,
      },
      url: `/user/update/${uid}`,
      data: data,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e: any) {
    console.log('get create user exception', e);
    throw new Error(e.response.data.error.message);
  }
}

export async function createSolanaTransaction(accessToken: string, body: unknown) {
  let response;
  try {
    response = await httpClient({
      method: 'post',
      url: '/solana/create',
      headers: {
        'x-access-token': accessToken,
      },
      data: body,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    if (e instanceof AxiosError) {
      console.log('create solana exception', e.response?.data.error);
      throw new Error(e.response?.data.error);
    }
  }
}
export async function GetReferalbyId( id: string, startDate: any, endDate: any,accessToken:string) {
  let response;
  try {
    response = await httpClient.get(`/referral/user/${id}?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        'x-access-token': accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log('get transaction by id exception', e);
  }
}
export async function GetRefer(id: any) {
  let response;
  try {
    response = await httpClient.get(`/user/referId/${id}`, {
      // headers: {
      //   'x-access-token': accessToken,
      // },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e: any) {
    console.log('get live fight exception', e);
    // return e.response.data;
  }
}