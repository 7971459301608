import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetUpcomingFights } from '../../services/Apiservices';

export const getUpcomingMatch = createAsyncThunk(
  'match/getUpcoming',
  async (args: any, { rejectWithValue }) => {
    try {
      const { accessToken, page, uid, date } = args;
      const result = await GetUpcomingFights(accessToken, page, uid, date);
      return result.fights;
    } catch (e: any) {
      rejectWithValue(e.response.data);
    }
  },
);

const upcomingMatchSlice = createSlice({
  name: 'match',
  initialState: {
    loading: false,
    data: [],
    totalPage: 1,
    page: 1,
    prev: false,
    next: false,
    isSuccess: false,
    message: '',
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },

    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(getUpcomingMatch.pending, (state) => {
      //   // state.loading = true;
      // })
      .addCase(getUpcomingMatch.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.totalPage = action.payload.totalPages;
        state.page = action.payload.page;
        if (action.payload.hasPrevPage === false) {
          state.prev = false;
          state.next = false;
        }
        if (action.payload.hasNextPage === false) {
          state.prev = false;
          state.next = false;
        }
        state.isSuccess = true;
        state.loading = false;
      })
      .addCase(getUpcomingMatch.rejected, (state, action: any) => {
        state.loading = false;
        state.message = action.payload;
        state.isSuccess = false;
      });
  },
});

export const { setPage, setData,setLoading } = upcomingMatchSlice.actions;
export default upcomingMatchSlice;
