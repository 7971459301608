import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';

const Language = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };
//   console.log(language, 'lang');

  const handleChange = (event: SelectChangeEvent<string>) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    changeLanguage(newLanguage);
  };
  return (
    <>
      {' '}
      {isMobile ? (
        <Box>
          <FormControl sx={{ m: 1, minWidth: 60 }} size='small'>
            <Select
              id='demo-simple-select'
              value={language}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiSelect-select': {
                  fontSize: '10px',
                  padding: '0px',
                  border: 'none',
                },
              }}
            >
              <MenuItem value='en'>
                <ReactCountryFlag
                  countryCode='US'
                  svg
                  style={{ width: '12px', marginRight: '8px' }}
                />
                English
              </MenuItem>
              <MenuItem value='ko'>
                <ReactCountryFlag
                  countryCode='KR'
                  svg
                  style={{ width: '12px', marginRight: '8px' }}
                />
                Korean
              </MenuItem>
              <MenuItem value='ja'>
                <ReactCountryFlag
                  countryCode='JP'
                  svg
                  style={{ width: '12px', marginRight: '8px' }}
                />
                Japanese
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      ) : (
        <Box>
          <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
            {/* <InputLabel sx={{ color: 'white' }}>Language</InputLabel> */}
            <Select
              id='demo-simple-select'
              value={language}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
            >
              <MenuItem value='en'>
                <ReactCountryFlag
                  countryCode='US'
                  svg
                  style={{ width: '20px', marginRight: '8px' }}
                />
                English
              </MenuItem>
              <MenuItem value='ko'>
                <ReactCountryFlag
                  countryCode='KR'
                  svg
                  style={{ width: '20px', marginRight: '8px' }}
                />
                Korean
              </MenuItem>
              <MenuItem value='ja'>
                <ReactCountryFlag
                  countryCode='JP'
                  svg
                  style={{ width: '20px', marginRight: '8px' }}
                />
                Japanese
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}
    </>
  );
};

export default Language;
