import { createTheme } from '@mui/material';
import { blackColor, greenColor, redColor } from './Colors';

const CustomTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#000000',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: ['Roboto'].join(','),
  },

  components: {
    // Name of the component
    // MuiButtonBase: {
    //   defaultProps: {
    //     // The props to change the default for.
    //     disableRipple: true, // No more ripple, on the whole application 💣!
    //   },
    // },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: `${redColor}`,
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          color: 'white',
          textAlign: 'center',
          width: 'fit-content',
          padding: '6px !important',
          alignItems: 'center'
        },
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: 'white',
          background: `${blackColor}`,

          '&:hover': {
            color: 'black',
            background: `${greenColor}`,
          },

          '&.Mui-selected': {
            background: `${greenColor}`,
            color: 'black',
            // borderRadius: '50%',
          },
        },
      },
    },
  },
});

export default CustomTheme;
