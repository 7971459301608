import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetBalance } from '../../services/Apiservices';

export const getUserBalance = createAsyncThunk(
  'user/getBalance',
  async (args: any, { rejectWithValue }) => {
    try {
      const { accessToken, uid } = args;
      const result = await GetBalance(accessToken, uid);
      return result.balance;
    } catch (e: any) {
      rejectWithValue(e.response.data);
    }
  },
);

const userBalanceSlice = createSlice({
  name: 'userBalance',
  initialState: { value: '--', loading: false },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserBalance.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserBalance.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loading = false;
      });
  },
});

export default userBalanceSlice;
