import { Backdrop, CircularProgress } from '@mui/material';
import { greenColor } from '../../config/Themes/Colors';

const FallBackUI = () => {
  return (
    <Backdrop
      sx={{ color: `${greenColor}`, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};

export default FallBackUI;
